* {
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root 
{
  --bg-color-black:#000;

}
:root{
  --clr-lg:#FF4D00;
  --body: #212531;
  --text-white: #edf0f1;
  --hover-link:#eb6709ac;
  --button-background:#FF4D00;
  --body-grey:gainsboro;
  --white:#fff;
}


@media screen and (max-width:2570px) {
	html, body {
		overflow-x: hidden;
}
}

html {
  /* width:100%;
  height: 100vh;
  margin: 0; */
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #243238;
  font-size: 16px;
  line-height: 1.5;

  /* max-width: 1200px; */
}


html body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #243238;
  font-size: 16px;
  line-height: 1.5;
}

body {
font-family: system-ui;
}


body{
  /* font-family: 'Roboto', sans-serif; */
  background-color: var(--body);
  color: #555f77;
  /* width: 100%; */
  /* width:100vw;
  height: 100vh; */
}


.scoll-to-top a{
  background-color: red;
  right: 11px;
  bottom: 40px;
  opacity: 0.8;

}

/* h1 {
  font-family: Georgia, 'Times New Roman', Times, serif;
} */



.d-none {
  display: none ;
}


a{
  color: #fff;
}




a{
  transition: all 0.4s ease-in-out;
}



















/* ABOUT US COMPANY*/




@media only screen and (max-width: 2560px) {
#company-id {
  max-width: 2560px;
}
}


@media only screen and (max-width: 1440px) {
  #company-id {
    max-width: 1440px;
  }
}


@media only screen and (max-width: 1024px) {
  #company-id {
    max-width: 1024px;
  }
}


@media only screen and (max-width: 768px) {
  #company-id {
    max-width: 768px;
  }
}


@media only screen and (max-width: 425px) {
  #company-id {
    max-width: 425px;
  }
}


@media only screen and (max-width: 375px) {
  #company-id {
    max-width: 375px;
  }
}



@media only screen and (max-width: 320px) {
  #company-id {
    max-width: 320px;
  }
}




#company-id{
  padding:40px 0 70px  0;
  margin: 0px;
  box-shadow: 0 0 0 0 #ffffff;
  z-index: 0;
  position: relative;
  /* background:#1a1a1a; */
  background:#191919;
}
.company-container-row{
  width: 100%;
  justify-content: center;
  /* margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; */
}

.company-row{
  justify-content: center;
}

.title-container{

  display: table-cell;
  border-bottom: 33px solid var(--button-background);
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  position: relative;
  /* width: 190px; */
  margin-bottom: 10px;

}

.hand-tree{
  display:inline;
}

@media only screen and (min-width: 768px)
{
  .company-container-row
   {
    display: flex;
    text-align: -webkit-center;
  }
  .hand-tree{
    width: 70px;
  }

.title-container{
  width: 190px;
}

.addon-title{
  font-size: 12px;
}
.addon-text,
.services-desc,
.title-subheading{
  font-size: 14px;
}

.addon-desc
.service-desc{
  font-size: 14px;
  width: 250px;
  display: inline-block;
}
}


@media only screen and (max-width: 767px)
{
.title-container{
  width:190px;
}

.addon-title{
font-size: 13px;
}

/* .addon-text, */
.services-desc,
.title-subheading{
  font-size: 10px;
  font-weight: 300;
}

.addon-desc,
.service-desc{
  font-size: 10px;
}

.hand-tree {
  width: 60px;

}

.title-subheading{
  max-width: 300px;
}
}

@media only screen and (min-width:1024px) {
  .title-subheading{
max-width: 440px;
  }
}




.addon-title{    
  /* font-size: 12px; */
    position: absolute;
    margin: 0 -1vh;
    display: flex;
    /* margin: auto; */
    padding-top: 0.5em;
    padding-left: 1.9em;
}

.addon-text{
  /* font-size: 42px; */
  /* text-align: start; */
  padding: 20px 0px 0px;
  margin-bottom: 20px;

}

.addon-desc{
  width: 100%;
  padding-top: 20px;
  width: 250px;
  display: inline-block;
  /* font-size: 16px; */
  color:#adb5bd;
  /* width: 350px;
  text-align: left; */
}

@media screen and (max-width: 2560px){
  hr {
    height: 3px;

  }
}

@media screen and (max-width: 768px){
  hr {
    height: 2px;

  }
}

hr {
  /* height: 3px; */
  background-color: var(--button-background);
  border: none;
  width: 70px;
  display: inline-flex;
}


.addon-text2{
  /* font-style: 20px; */
  text-align: center;
  /* padding-bottom: 10px; */
  padding-top: 10px;
}

/* 
@media (min-width: 1400px) {
  .company-container-row{
    max-width: 1140px;
  }
}

@media (min-width: 1380px) {
  .company-container-row{
    max-width: 1320px;
  }
}

@media (min-width: 1380px) {
  .company-container-row{
    max-width: 1320px;
  }
}

@media (min-width: 992px) {
  .company-container-row{
    max-width: 960px;
  }
}

@media (min-width: 768px) {
  .company-container-row{
    max-width: 720px;
  }
}

@media (min-width: 1380px) {
  .company-container-row{
    max-width: 540px;
  }
}


.company-row{
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}


.company-row:after{

  content: "";
  display: table;
  clear: both;
}
.company-col-1{
  align-self: stretch;
}

@media (min-width: 992px) {
  .company-col-1{
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
} */

/* .company-col {
  width: 250px;
  display: inline-block;
} */


.company-row{
  display:contents;
  color:#fff;
  flex-wrap: wrap;
  align-items: center;
  /* padding: 100px 0; */
}

.company-col-1 
.company-col-2 
.company-col-3 
{
  columns:  auto;
  padding:0 15px;
  text-align:center;
}

.company_1{
  width: 250px;
  display: inline-block;
}

.company-col-1{
  align-self: center;
}

.company-col-2-row{
  /* background-color:darkred; */
  /* border-radius: 6rem; */
  padding: 30px;
  width: fit-content;
}

.company-col-2-row {
  margin:40px 25px;
}



.addon-desc2 {
  color:#fff;
  width: 100%;
}


@media screen and (max-width: 768px) {
.excavator{
  width: 50px;
}

.addon-text3,
.addon-text2
{
  font-size: 20px;
}
.service-title{
  font-size: 14px;
}
}


@media screen and (min-width: 769px) {
  .excavator{
    width: 60px;
  }

.addon-text3,
.addon-text2
{
    font-size: 24px;
  }
}


.excavator{
  display: inline;
  margin: 0 60px;
}

.addon-text3{
  padding-top: 10px;

}

.company-col-3{ 
  margin: 0 60px;
  padding: 10px 30px;
}

.company-col{
  place-self: center;
  /* background-color: darkred; */
}

@media screen and (max-width: 768px) {
  .company-col{
    
    text-align: -webkit-center;
  }
}





/* OUR SERVICES */


.services-container-row{
  /* width: 1140px; */
  display: inline-flex;
  color:#fff;
}


@media only screen and (max-width: 2560px) {
.services-row {
  max-width: 2500px;
  display: flex;
}
.main-services,
.sppb-title-heading{
  font-size: 30px;
}
}

@media only screen and (max-width: 1440px) {
  .services-row {
    max-width: 1440px;
    
  }
  .main-services,
  .sppb-title-heading{
    font-size: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  .services-row {
    max-width: 1024px;
  }
  .main-services,
.sppb-title-heading{
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .services-row {
    max-width: 768px;
    display: grid;
    place-items: center;
    
  }
  .main-services,
  .sppb-title-heading{
    font-size: 24px;
  }
}

@media only screen and (max-width: 425px) {
  .services-row {
    max-width: 425px;
  }
  .main-services,
  .sppb-title-heading{
    font-size: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .services-row {
    max-width: 375px;
  }
}

@media only screen and (max-width: 320px) {
  .services-row {
    max-width: 320px;
  }
}


.services-row {
  width: 100%;
  margin-top: 50px;
  /* display: flex; */
  align-items: center;
}


#ourservices{
  background-color:var(--bg-color-black);
  width: 100%;
  height: auto;
  padding: 100px 0 70px;
}



.services-col-1{
  text-align: center;
  width: 250px;
  position: relative;
  justify-content: center;
  margin-bottom: 20px;
  /* background-image: url('./assets/images/Hero.jpeg'); */
}
@media screen and (max-width:2570px){
  .service-title-container{
    width: 150px;
  }

  .services-title{
    font-size: 9px;
  }

}




.service-title-container{
  display: inline-block;
  border-bottom: 33px solid var(--button-background);
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  position: relative;
  width: 200px;
  margin-bottom: 10px;
}

.services-title{
  
  font-size: 13px;
  justify-content: center;
  width: -moz-fit-content;
  position: absolute;
  inline-size:max-content;
  margin: 8px 10px;
}



.services-desc{
  /* font-size: 18px; */
  color:#adb5bd;
  font-weight: 200;
  /* width: 300px; */
  /* float: right; */
  /* margin-right: 20px; */
}


.right-hr{
margin: 85px;
/* height: 3px; */
/* background-color: #f34a04; */
/* border: none; */
/* width: auto; */
margin-top: 10px;
margin-bottom: 20px;

}


.service-img-logo,
.service-title{
  transition: all 0.8 ease-in-out;
}


/* COL 2 */






.services-col-2{
  width: 100%;
  text-align: -webkit-center;
  /* padding-top: 50px; */
  position: relative;
  /* padding-right: 15px; */
  /* padding-bottom: 70px; */
  /* padding-left: 50px; */
  padding: 30px 20px;

  box-shadow: 0 0 0 0 #fff;
  background:#000;
  display: grid;
}


.service-img{
  /* display: flex; */
  /* position: absolute; */
  /* left: 1rem; */
  float: left;
}

.service-img-logo{
  width: 50px;
}

.service-img{
  transition: all 0.8;
}

.service-img:hover
.service-img-logo
{
  filter: brightness(0) invert(1);

}


.service-img, 
.service-title{
/* color:red; */
color: var(--button-background);

}

.service-1:hover
.service-title
{
  color: #fff;
}

.service-1:hover
.service-img{
  filter: brightness(0) invert(1);
}




.service-title{
  align-self: center;
  padding-left: 20px;
  display: list-item;
}

/* .service-title:hover{
  color:red;
} */



.service-1,
.service-2,
.service-3,
.service-4{
  margin-bottom: 20px;
}



.service-1{
  width: 100%;
  margin-bottom: 20px;
  max-width: 500px;
  cursor: pointer;
  display: list-item;
  position: relative;
}


/* OUR PROJECT */







#latest-project{
  padding-top: 50px;
  padding-right: 0px;
  padding-bottom: 50px;
  padding-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  box-shadow: 0 0 0 0 #ffffff;
  background:#191919;
  color:white;
}


.sppb-section{
  
  position: relative;
  
}

.sppb-section-content-center{
  display: flex;
  width: 100%;
}



.sppb-section-title {
  margin-bottom: 40px;
  position: relative;
  
  text-align: -webkit-center;
}


.sppb-title-heading{
  /* font-size: 42px; */
    line-height: 1.2;
    margin-bottom: 15px;
    position: relative;
    flex-direction: column;
    z-index: 0;
    padding-bottom: 18px;
    font-weight: 700;
}

.subtitle-title{
  border-bottom: 33px solid var(--button-background);
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  position: relative;
  width: 200px;
  margin-bottom: 10px;
}

.sppb-subtitle{
  font-size: 13px;
  justify-content: center;
  width: -moz-fit-content;
  position: absolute;
  margin: 7px 18px;
}

/* 
.sppb-title-heading{
  font-size: 42px;
  line-height: 1.2;
  margin-bottom: 15px;
} */

.title-subheading{
  /* font-size:16px; */
  color:#adb5bd;
  /* font-weight: 200; */
  /* padding: 0 440px; */
  width: 100%;
  display: flex;
}




.hr-projects{
  width: 72px;
  display: inline-flex;

}

.projects-list-title{
  display: none; /* remove after fixing */
  /* display: grid; */
  margin-bottom: 20px;
  grid-template-columns: repeat(5, 1fr);
}


@media screen and (max-width: 768px){
  .list_items {
    font-size: 10px;
  }
}


@media screen and (min-width: 769px){
.list_items {
  font-size: 13px;
}
}

.list_items {
  padding: 0px 8px;
  font-weight: 600;
  cursor: pointer;
  
}


a:hover, a:focus, a:active {
  color: #f34a04;
}

.projects-row{
  justify-content: center;
  width: 100%;
}







/* PROJECT-ITEM */


.project{
  position: relative;
  margin: 25px;
}




 .project-title {
   font-size: 15px;

 }


 


 .project
 .project-content{
  width: 100%;
  transform: translateY(-20px); 
  animation: 2s anim-lineUp ease-out infinite ;
  position: absolute;
  bottom: -22px;
  left: 0px;
  color: #ffffff;
  background: red;
  text-align:center;
  font-weight:bold;
  opacity:0;
  visibility:hidden;

 }

@media only screen and (max-width: 768px){
  .project-wrapper{
    grid-template-columns: repeat(2,1fr)
  }
}

@media only screen and (min-width: 770px){
  .project-wrapper{
    grid-template-columns: repeat(3,1fr)
  }
}

@media only screen and (max-width: 425px){
  .project-wrapper{
    grid-template-columns: repeat(1,1fr)
  }
}

 .project-wrapper{
  place-items: center;
  /* place-content: center; */
  flex-wrap: wrap;
  display: grid;
  max-width: 1000px;
  /* grid-template-columns: repeat(3, 1fr); */
 }


.project-image:hover
.project-img-item{
-webkit-filter: grayscale(100%) blur(3px);
filter: grayscale(100%) blur(3px);
}



.project-image:hover
.project-content{
  visibility: visible;
  opacity: 0.8;  
}

.project-img-item{
  -webkit-filter: grayscale(0) blur(0);
  filter: grayscale(0) blur(0);
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
  /* width: 250px;
  height: 250px; */
  background-size: cover;
  object-fit: cover;
  
}


@media screen and (max-width: 2570px){
  .project-img-item{
    width: 250px;
    height: 250px;
  }
}


@media (min-width: 200px) and (max-width: 768px){
  .project-img-item{
    width: 150px;
    height: 150px;
  }
}





.content{
  font-size: 12px;
  height: 23px;
  color: darkgrey;
  width: 100%;
}



.project-content{
  animation: 2s anim-lineUp ease-out infinite;
  transition: visibility 0.7s, opacity 0.5s ease-in-out ;
}




/*  CONTACT FRONT PAGE */



.contact-wrap-row{

  display: flex;
  justify-content: center;
  padding-top: 30px;

}


.contact-wrap-left{
  font-size: 26px;
}

.contact-wrap-right{
  cursor: pointer;
  background: var(--button-background);
    padding: 9px 34px;
    font-size: 17px;
    margin-left: 150px;
    border: 1px solid #f34a04;
    
}

.contact-wrap-right:hover{
  background:black!important;
  color: var(--button-background);
}





/* WHY US */


#why-us{
  color: #fff;
  background-color: #191919;
  /* width: 100%; */
}

@media screen and (max-width: 2570px){
  .why-us-items{
    grid-template-columns: repeat(4, 1fr);
  }

  .why-choose{
    font-size: 10px;
  }

  .why-company-title{
    font-size:30px;  
  }
}


@media screen and (max-width: 1124px){
  .why-us-items{
    grid-template-columns: repeat(2, 1fr);
  }
}


@media screen and (max-width: 768px){
  .why-choose{
    font-size: 10px;
  }

  .why-company-title{
    font-size: 17px;
  }
}


/* iphone 12 pro max width 500px */
@media screen and (max-width: 500px){
  .why-us-items{
    grid-template-columns: repeat(1, 1fr);
  }
}

.why-us-container {
  width: 100%;
  display: inline-flex;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;


}

.why-us-item{
  /* padding: 100px 30px; */
  width: 100%;
  padding: 20px 0px;
  height: auto;
}

.why-us-items{
  /* grid-template-columns: repeat(4, 1fr); */
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  display: grid;
}


.choose-border{

  border-bottom: 33px solid var(--button-background);
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  position: relative;
  width: 224px;
  display: inline-block;
}

.why-choose{
  /* font-size: 13px; */
  font-weight: 700;
  font-size: 13px;
  justify-content: center;
  width: -moz-fit-content;
  position: absolute;
  margin: 8px 26px;
}


.why-company-title{
/* font-size:35px;   */
margin-top: 10px;
/* margin: 0 0 25px; */
/* padding: 0 0 18px; */
text-align: center;
}

@media screen and (max-width: 1560px){
  .why-company {
    font-size: 16px;
  }
}


@media screen and (max-width: 768px){
  .why-company {
    font-size: 11px;
  }
}


.why-company{
  /* font-size: 16px; */
  color: #adb5bd;
  margin-top: 20px;
  font-weight: 300;
  text-align: center;
  margin: 30px;
  
}


.why-left{
  text-align: center;
}

.our-company,
.our-safety{
  background-color: #000;
}

.our-experience
.our-tech{
  background-color: #212529;
  padding: 20px 0;
}

@media screen and (max-width: 2560px){
  .why-us-number {
    width: 80px;
    height: 80px;
    font-size: 40px;
    line-height: 4.5rem;
  }
}

@media screen and (max-width: 768px){
  .why-us-number {
    width: 60px;
    height: 60px;
    font-size: 30px;
    line-height: 3.5rem;
  }
}


.why-us-number{
  background: var(--button-background);
  display: inline-block;
  /* width: 80px; */
  /* font-size: 40px; */
  margin: 0 114px;
  text-align: center;
  /* height: 80px; */
  /* line-height: 80px; */
  transition-duration: 5s;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
.why-us-hover:hover
.why-us-number{
  /* transform: scale(1.5); */
  transform: translate(0px, -20px);
  background: var(--button-background);
  border-radius: 30px;
  transform: scale(1.5);
}

.why-us-hover:hover
.why-title{
color:var(--button-background);
}

.why-title{
  font-size:22px;
  margin: 20px 0 5px;
  text-align: center;
  transition: all 0.4s ease;
}



.why-us-hover{
 margin-top: 20px;
}


/* 



TECH



*/


.tech{
  background: #191919;
  color: white;
  width: 100%;
  height: auto;
}

@media screen and (max-width: 2570px){
  .tech-image{
    width: 500px;
    height: 500px;
  }

  .tech-box{
    padding: 50px 50px;
    max-width:600px;
  }

  .tech-title{
    font-size: 42px;
  }

  .tech-para{
    font-size: 16px;
  }

  .tech-container{
    display: flex;
  }
}

@media screen and (max-width: 1024px){
  .tech-image{
    width: 300px;
    height: 300px;
  }

  .tech-box{
    padding: 50px 30px;
    max-width:500px;
  }
}


@media screen and (max-width: 768px){
  .tech-title{
    font-size: 30px;
  }

  .tech-para{
    font-size: 11px;
  }

  .tech-box{
    max-width: 400px;
  }

  .tech-image{
    width: 250px;
    height: 250px;
  }
}


@media screen and (max-width: 425px){
  .tech-container{
    display: grid;
  }
}
.tech-container{
  align-items: center;
  justify-content: center;
/* display: flex; */
/* place-content: flex-start; */
padding: 50px 0;
/* margin-left: 30vh; */
}

.tech-img{
  /* width: 500px; */
  filter: grayscale(1);
  justify-self:center;
  /* height: 500px; */
}

/* .tech-image{
  width: 500px;
  height: 500px;

} */


.technology-title{

  border-bottom: 33px solid var(--button-background);
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  position: relative;
  width: 160px;
}

.tech-box{
  /* position: absolute; */
  align-self: center;
  background: #212529;
  /* padding: 50px 50px; */
  /* left: 77vh; */
  width: 100%;
  /* max-width:600px; */
  text-align: -webkit-center;
}

.technology{
  font-size: 13px;
  justify-content: center;
  width: -moz-fit-content;
  position: absolute;
  margin: 7px 7px;
}

.tech-title{
  /* font-size: 42px; */
  margin: 0 0 10px;
  font-weight: 400;
  /* padding: 0 0 18px; */
}



.tech-para{
  margin-top: 20px; 
  /* font-size:16px; */
  color: #adb5bd;
  text-align: start;
}


.tech-hr{
  display: inline-block;
}



/*

TESTIMONIALS

*/

/* @media screen and (min-width: 768px){
  .main-testimonials {
    margin-top: 50px;
  }
} */

.main-testimonials{
  /* margin-top: 50px; */
  display: inline-block;
}


@media screen and (max-width: 768px) {
  .testimonials {
    padding: 10px 0 10px;
  }
}

@media screen and (max-width: 2570px) {
  .testimonials {
    padding: 100px 0 70px;
  }
}




.testimonials{    
width: 100%;
text-align: -webkit-center;
background:#151515;
/* padding: 100px 0 70px; */
}


@media screen and (max-width: 2570px){
  .testimonials-category{
    font-size: 13px;
  }
}

.testimonials-category{    
  /* font-size: 16px; */
  position: absolute;
  color: white;
  margin: 7px 10px;
}


.testimonials-shape{
  border-bottom: 33px solid var(--button-background);
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  position: relative;
  width: 200px;
}

.testimonials-para{
  color: white;
  font-size: 16px;
  /* padding: 0 0 15px; */
  margin-top: 20px;
  font-weight: 300;
}

.carousel-root {
  width: 64% !important;
  /* margin: auto !important; */
  margin-top: 3% !important;
}

.carousel .slide {
  /* background: #fff !important; */
  color: #fff;
  height: 100%;
}

@media screen and ( min-width: 426px ) {
  .carousel .slide img {
    width: 120px!important;
    height: 120px!important;
  }
}


@media screen and ( max-width: 425px ) {
  .carousel .slide img {
    width: 80px!important;
    height: 80px!important;
  }
}



.carousel .slide img {
  /* width: 120px !important; */
  border-radius: 50%;
  /* height: 120px !important; */
}

.rating{
  padding-top: 10px ;
  color: var(--button-background);
  font-size: 16px;
}

@media screen and (max-width: 2500px) {
  .myCarousel{
    max-width: 500px;
  }

  .myCarousel > h3 {
    font-size: 22px;
  }

  .myCarousel > h4 {
    font-size: 16px;
  }

  .myCarousel > p {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .myCarousel > h3{
    font-size: 16px;
    margin-top: 20px;
  }

  .myCarousel > h4 {
    font-size: 9px;
  }

  .myCarousel > p {
    font-size: 9px;
  }
}

.myCarousel {
  background: #000;
  margin-top: -6%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  /* height: 286px; */
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel > h3 {
  color: var(--button-background);
  /* font-weight: 100; */
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  /* font-size: 22px; */
}

.myCarousel > h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  /* color: #adb5bd; */
  /* font-size: 16px; */
}

.myCarousel > p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #adb5bd;
  /* font-size: 16px; */
  font-family: sans-serif;
  /* max-height: 67px; */
}

.myCarousel > p:before {
  content: "“";
  /* color: #aaa; */
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel >  p:after {
  content: "”";
  /* color: #aaa; */
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  /* background: #000 !important; */
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
  width: 100%;
}


/* 

Responsive Media 

*/



@media only screen and (max-width: 2560px) {
  .App {
    max-width: 2560px;
  }
  .container {
    max-width: 2000px;
  }
}

@media only screen and (max-width: 1440px) {
  .App {
    max-width: 1440px;
  }
}

@media only screen and (max-width: 1024px) {
  .App {
    max-width: 1024px;
  }
}

@media only screen and (max-width: 768px) {
  .App {
    max-width: 758px;
  }
}

@media only screen and (max-width: 425px) {
  .App {
    max-width: 425px;
  }
}

@media only screen and (max-width: 375px) {
  .App {
    max-width: 375px;
  }
}

@media only screen and (max-width: 320px) {
  .App {
    max-width: 320px;
  }
}



@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    /* background: #fafafa; */
    margin-top: -9%;
    /* width: 88%; */
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: auto;
  }

  .carousel .slide img {
    /* width: 24% !important; */
    border-radius: 50%;
  }
}

@media  screen and ( max-width: 425px ) {
  .carousel.carousel-slider{
    display: grid;
    width: 250px;
  }
}



.image_container{
  margin-top: 50px;
  width: 100%;
}


/*

BLOG 


*/


#Blog {
  background: #000;
  color: #fff;
  padding: 100px 0 50px;
}


.blog-items-container{
  display: inline-block;
  width: 100%;
  /* max-width: 1000px; */
}


/* .blog-items{
  background: #000;
} */

.blog-container{
  display: inline-block;
  width: 100%;
  background-color: #000;
}

.blog-row{
  width: 100%;
}

.our-blog-container{
  border-bottom: 33px solid var(--button-background);
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  position: relative;
  width: 190px;
  display: inline-block;
}


.blog-header{
  font-size: 13px;
  position: absolute;
  color: white;
  margin: 7px 19px;

  /* font-size: 13px;
  background: red;
  width: 100px;
  display: inline-block; */
}

@media screen and (min-width: 1140px) {
  .blog-title {
    font-size: 42px;
  }
}

@media screen and ( max-width:1100px) {
  .blog-title{
    font-size: 30px;
  }
}


.blog-title{
  /* font-size:42px; */
  font-weight: 800;
}

.blog-hr{
  display: inline-block;
}


.blog-para {
  font-size: 16px;
  margin-top: 20px;
  font-weight: 200;
  /* margin-bottom:20px; */
}

.blog-item-detail{
display: flex;
justify-content: center;
/* margin: 10px 0; */
}

.blog-item-title:hover{
  color: white;
}

.blog-item-title{
  font-weight: 500;
  margin-top: 20px;
  line-height: 1.1;
  color: var(--button-background);
}

@media screen and (max-width: 2570px) {
  .blog-item{
    max-width: 240px;
  }

/* .blog-img {
  width: calc(100% - 60px);

} */

.blog-item-title{
  font-size:22px;
}

.blog-item-detail{
  margin: 10px 0;
}

}




@media screen and (max-width: 1440px) {
  .blog-item{
    max-width: 200px;
  }
  .blog-item-title{
    font-size: 19px;
  }

}




.blog-item{
  /* cursor: pointer; */
  cursor:not-allowed;
  display: inline-block;
  width: 100%;
  /* background: #191919; */
  /* width: 150px; */
  transition: all .8s ease-in-out;
  box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
}

.blog-item:hover{
  transform: scale(1.1); 
}




.blog-item-category{
  font-size: 10px;
  color: var(--button-background);
  margin-right: 10px;
}

.blog-item-date{
  font-size: 10px;
  color: #adb5bd
}

.blog-article{
  margin: 50px 0;
  padding: 0;
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px; */
}

.blog-para-item{
  font-size: 11px;
  font-weight: 500;
  color:#adb5bd;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


@media screen and (max-width: 2570px) {
  .blog-item-container{
    grid-template-columns: repeat(2,1fr);
  }
}


@media screen and (max-width: 768px) {
  .blog-item-container{
    grid-template-columns:repeat(2,1fr) ;
  }
}


@media screen and (max-width: 375px) {
  .blog-item-container{
    grid-template-columns:repeat(1,1fr) ;
  }
}






.blog-item-container{
  flex-wrap: wrap;
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
}

.blog-text-container{
  /* margin: 0 0 30px; */
  background-color: #151515;
  height: 150px;
  display: grid;
  align-content: center;
  /* padding: 25px; */

}






/* 



FOOTER



*/


.footer{
  background-color: #151515;
  color: #fff;
  padding: 10px 0 0px;
  line-height:1.6;
  width: 100%;
}

.footer-container{
  padding-left: 15px;
  padding-right: 15px;
  max-width: fit-content;
  display: inline-block;
}

.footer-row-container{
  display: flex;
  flex-wrap: wrap;
}


@media screen and (min-width:900px){
  .footer-top{
    grid-template-columns: repeat(3,1fr);
  }
}


@media screen and (max-width: px) {
  .footer-top{
    grid-template-columns: repeat(1,1fr);
  }
}

.footer-top{    
  /* position: relative; */
  /* z-index: 3; */
  display: grid;
  justify-items: center;
  /* flex-wrap: wrap; */
  /* flex-wrap: wrap; */
  /* justify-items: center;   disabled at 325px  */  
  /* place-content: center;    disabled at 325px */
  /* margin-bottom: 140px; */
  /* max-width: 1200px; */
  /* width: 100%; */
  /* grid-template-columns: repeat(3,1fr) */
}

.footer-col-1{
  display: none;
  /* flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; */

}



@media screen and (min-width:346px){
  .footer-title{
    font-size: 25px;
  }
}



@media screen and (max-width:345px) {
  .footer-title{
    font-size: 20px;
  }
  .footer-col-link{
    font-size: 20px;
    line-height: 2rem;
  }
}



.footer-title{
  display: grid;
  margin: 0px 0px 0px 0px;
  text-transform: none;
  /* font-size: 32px; */
  /* position: relative; */
  /* display: -webkit-flex; */
  /* -webkit-flex-direction: column; */
  /* flex-direction: column; */
  /* z-index: 0; */
  text-align: center;
  /* cursor: pointer; */
  /* padding: 0px 0px 15px 0px; */
  /* display: flex; */
  /* padding-bottom: 10px; */
  /* margin-bottom: 25px !important; */
}

.footer-hr{
  margin: 15px 0;
  width: 50px;
  /* display: flex; */
}

.footer-para{
  font-size: 16px;
  color: #9999;
  width: 100%;
  text-align: start;

}

.footer-col-2,
.footer-col-3{
  margin-bottom: 20px;
  /* flex: 0 0 15%; */
  /* position: relative; */
  /* width: 300px; */
  text-align-last:center ;
  /* min-height: 1px; */
  padding-right: 15px;
  padding-left: 15px;
  text-align: --webkit-center;
}


.title_countries{
  width: 100%;
  display: grid;
  align-content: center;
  align-items: center;
}

.hr-map{
  width: 10vh;
  justify-self: center;

}

.footer-col-4{
  
  width: 300px;
  text-align-last: center;
  /* min-height: 1px; */
  padding-right: 15px;
  padding-left: 15px;
  /* position: relative;
  width: 250px;
  display: inline-grid;
  justify-items: center;
  justify-content: center; */
}

.footer-col-4:hover
.footer-map{
  display: grid !important;
}


.footer-col-3:hover
.footer-col-links{
  display: grid!important;
}


.footer-col-2:hover 
.footer-col-links {
  display: grid!important;
}


@media screen and ( max-width: 768px ) {
  .footer-col-links{
    display: none;
  }
  .footer-map{
    display: none;
  }
}

.footer-col-link{
  /* disabled at 425px */
cursor: not-allowed;
  /* font-size: 25px;  */
  text-align: start;
  font-weight: 300;
  color: darkgray;
}

.footer-col-link{
  cursor: pointer;
  /* line-height:3rem;  // disabled at 425px  */
}

.footer-col-link:hover{
  transition: all 0.4s ease;
  color: red;
}

@media screen and (min-width: 1001px) {
  .footer-map{
    width: 250px;
  }
}


@media screen and (max-width:1000px) {
  .footer-map{
    width: 200px;
  }
}

.footer-map{
  filter: contrast(0.5);
  /* position: absolute; */
  /* width: 100%; */
  /* top: 5vh; */
  /* left: -5vh; */
  /* width: 300px; */
}



.footer-bottom-col{
  display: flex;
  align-items: center;
  place-content: center;
}


.footer-bt-1,
.footer-bt-2,
.footer-bt-3,
.footer-bt-4
 {

   /* flex: 0 0 25%; */
   /* max-width: 25%; */
   /* position: relative; */
   /* z-index: 1; */
   /* width: 250px; */
  margin: 0 20px;
  display: flex;
  /* border: 1px solid #9999;  */
  /* padding: 20px; */
  justify-content: space-around;
}

@media screen and (min-width:769px){
  .bt-1-right,
  .bt-2-right,
  .bt-3-right,
  .bt-4-right{
    margin-left: 20px;
  }
}


@media screen and (max-width:768px) {
  .bt-1-right,
  .bt-2-right,
  .bt-3-right,
  .bt-4-right
  {
    display: none;
  }
  
}



.footer-bottom{
  width: 100%;
  padding-bottom: 10px;
  /* display: inline-block; */
  display: flex;
  /* margin-top: 50px; */
}


.footer-bottom-link {
  color: var(--button-background);
  cursor: pointer;
  transition: all 0.5s ease;
}

.footer-bottom-link:hover{
  color: #fff;
}

.footer-bottom-col{
  /* display: grid; */
  /* align-items: center; */
  /* margin: 10px 20px; */
  /* flex: 0 0 25%; */
  width: 100%;
  /* grid-template-columns: repeat(4,1fr); */
}

.bt-1-left:hover{
  color:white
}

.bt-2-left:hover{
  color:white
}

.bt-3-left:hover{
  color:white
}

.bt-4-left:hover{
  color:white
}

.bt-1-left,
.bt-2-left,
.bt-3-left,
.bt-4-left
{
  align-items: center;
  transition: all 0.4s ease;
  font-size: 30px;
  color: var(--button-background);
  /* padding-right: 20px; */
}

.bt-title{
  font-size:22px
}


.bt-detail{
  font-size:16px;
  color:#9999;
  cursor: pointer;
}

.bt-detail:hover{
  /* color:red; */
  color:var(--button-background);
}

@media screen and (max-width:520px){
  .media-left{
    font-size: 11px;
  }
  .media{
    font-size: 13px;
  }
}




.media-right{
  margin-left: 20px;
}
.media-left{
  /* font-size: 14px; */
  color: #9999;
  font-family: monospace;
}

.footer-media{
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.media-links{
  display: flex;
}

.media-facebook,
.media-tiktok,
.media-instagram{
  margin-right: 10px;
}

.emougio{
  cursor: pointer;
  color: red;
  
}

.media{
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.media:hover{
color:var(--button-background);
}

  /* 
  
  Services
  
  */

/*   
  .bottom-wave {
    width: 100%;
    height: 100%;
    max-height: 15.8rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-image: url(https://d39afr5wio5j08.cloudfront.net/assets-p/about/about-hero-bg-d6d968d4a566bfa7ee99d9f21fad58987f6f40648d220c804d3bc333d23c6b1f.svg);
    z-index: 0;
    
    
} */

  .cards {
    padding: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;
    /* background: var(--text-white) */
    
  }




  .cards-row {
    justify-content: center;
    width: 100%;
    text-align: center;

  }

  
  .cards-row > h1 {
    text-align:center;
    padding:10px;
    background: var(--button-background);
    color: #fff;
    border: 0;
    border-radius: 12px;
    overflow: hidden;
    margin: 0;
    display:inline-block;
    font-weight: 400;
    /* text-align: center;
    border-radius:50px;
    background:var(--hover-link);
    font-weight: 600;
    color: var(--body);
    font-size:30px;
    padding:9px 25px;
    text-decoration: underline;
    letter-spacing: 1px;
    margin-bottom: 20px;
    justify-content: flex-end;
    display: flow-root; */
  }
  
  .cards-container {
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: auto;
    padding: 5px 40px;
    background-color: var(--white);
    border-radius:10px;
  }
  
  .cards-wrapper {
    position: relative;
    margin: 50px 0 45px;
    display: flex;
    /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));  */
    grid-gap: 3em;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .cards-items {
    margin-bottom: 24px;
    background-color:var(--text-white);
    border-radius: 20px;
    width:280px;
    height:280px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    
    
  }
  
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    /* width: 100%; */
    /* box-shadow: 0 6px 20px rgba(230, 210, 38, 0.17); */
    /* -webkit-filter: drop-shadow(0 6px 20px rgba(230, 210, 38, 0.17)); */
    /* filter: drop-shadow(0 6px 20px rgba(230, 210, 38, 0.17)); */
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    padding:16px;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    max-width: 250px;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    background-color: var(--body);
    font-family: "Montserrat", sans-serif;
    box-sizing: border-box;
  }
  
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    /* max-width: 350px; */
    height: 100%;
    border-radius:10px;
    max-height: 350px;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
    border-radius: 10px;
  }
  
  .cards__item__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item__text {
    line-height: 24px;
    font-size: 15px;
    font-family: 'Montserrat';
    font-weight: 500;
    color: var(--body);
  }
  
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 390px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }




  /* 
  
  TEAM
  
  */


  .team {
    padding:4rem;
    background-color:var(--text-white);
    font-family: "Montserrat", sans-serif;
  }


.team-container {
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  border-radius:10px;
  background-color:var(--white);
  /* display: flex; */
  justify-content: center;
}

.team-wrapper{
  /* position:relative;
  margin: 50px 0 45px; */
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px,1fr)); */
  display: flex;
    width: 100%;
    align-items: center;
    justify-content: inherit;
}

.team-items{
  /* margin-bottom: 24px; */
  padding:20px;
  /* background-color:var(--text-white); */
  /* border-radius: 50px; */
  margin: 10px;
}

.team-item{
  flex:1 1 150px;
  margin: 0 1rem;
  border-radius: 10px;
}


.team-pic{
  position: relative;
  width: 100%;
  

}

.team-item{

  width: 100%;
  max-width: 250px;
  border-radius: 50%;
}

.team-item-info{
  padding: 20px 30px 30px;
  width:100%;
  max-width:250px;
}

.team-item-text{
  font-size: 13px;
  font-weight: 300;
  padding: 10px 0;
}

.team > h1 {
  text-align: center;
  border-radius:50px;
  background:var(--hover-link);
  /* font-family: "Montserrat", sans-serif; */
  font-weight: 600;
  color: var(--text-white);
  font-size:30px;
  padding:9px 25px;
  text-decoration: underline;
  /* letter-spacing: 1px; */
  margin-bottom: 20px;
}

.team-headline {
  font-size: 18px;
  text-align:center;
  color: var(--hover-link);
}

.view{
  height: 100px;
  position: relative;
  /* border: 3px solid green; */
  align-items: center;
  justify-content: center;
  display: flex;
}

.viewMore{
  margin: 0;
  position: absolute;
  /* top: 50%; */
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 15px;
  background: var(--button-background);
  padding: 18px 30px;
  border-radius: 30px;
  font-weight: 400;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
}

.viewMore:hover{
  background-color: var(--hover-link);
}



/*

PROMOTION

*/

.vertical{
  width: 1px;
  height: 20px;
  display: inline-block;
  color: #000;
  margin-left: 10px;
  margin-right: 10px;
}
#promotion{
  /* padding-top: 7px; */
    background-color: black;
    float: right;
    width: 300px;
    margin-left: 15px;
    /* padding-bottom: 15px; */
    padding: 20px;
    margin-right: 15px;
    color: white;
    margin-top: 55px;
}
.createArticle {
  margin-bottom: 20px;
  margin-top: 10px;

}

.createAricle_btn {
  width: 160px;
  height: 40px;
  display: flex;
  background-color: var(--button-background);
  color: #000;
  font-weight: 700;
  justify-content: center;
  align-items: center;
}

.followus {
  margin-bottom: 12px;
  margin-top: 10px;
  margin-right: 0;
}

.followUs {
  margin-left: 0;
  padding-left: 0;
  margin-top: 9px;
  font-size: 12px;
  font-weight: 400;
  clear: both;
  width: 312px;
}


.followus1 {
  padding-bottom: 5px;
  font-size: 0.8em;
  float: left;
  list-style: none;
}
.share{
  /* min-width: 81px; */
  padding-right: 10px;
  padding-left: 10px;
  /* padding-bottom: 5px; */
  font-size: 1.1em;
  float: left;
  list-style: none;
}



/* 



COMPANY - PAGE



*/
.sppb-row-container{
  width: 100%;
}



.company_page{
  width: 100%;
  height: 100%;
  padding-top: 10vh ;
  color:white;
  /* display: flex; */
  place-content: center;
  /* position: absolute;
  top: -10vh;
  opacity: 0.6;
  overflow: hidden;
  object-fit: cover; */
  /* z-index: -1;s */
  
}




.company_pg-image{
  border-radius:20px ;
  opacity: 0.7;
}

@media screen and (max-width:768px) {
  .company_pg-title{
    font-size: 25px;
  }

  .company_pg-image {
  width: 350px;
  }
}

@media screen and (min-width:769) {
  .compan_pg-title{
    font-size:30px
  }
}


.company_pg-title{
  margin: 0 0 30px;
  /* font-size: 36px; */
}


.company_page-container{
  width: 100%;
  text-align: -webkit-center;
  color:#fff;

}


.bg-company-pg{
  width: 100%;
  position: absolute;
  z-index: -1;
  height: 100%;
  top: -29vh;
  opacity: 0.3;
}


@media screen and (max-width:768px) {
  .company_pg-hd-title{
    margin-top: -12x;
  }

  .company_pg-hd-title-home{
    font-size: 10px!important;
  }
  
}


@media screen and (min-width:300px) {
  .company_pg-hd-title{
    margin-top: 10px;
    font-size: 12px;
  }

  .company_pg-hd-title-home{
    font-size: 14px;
  }
}


.company_pg-hd-title{
  
  /* font-size: 16px; */
  justify-content: center;
  position: absolute;
  align-items: center;
  /* display: flex; */
  /* margin-top: 10px; */
  
}


.company_pg-hd_header{
  display: contents;
}

.company_pg-hd-title-home{
  /* font-size: 10px; */
  font-weight: 600;
}

.company_pg-hd-title-home:hover

{
  color:#000;
  /* font-weight: 600; */
  
}



@media screen and (max-width: 768px) {
  .company_page-header{
    border-bottom: 33px solid var(--button-background);
    max-width: 210px;
  }

  .company_pg-pin{
    font-size: 14px;
  }

  .span-company{
    font-size: 10px;
  }
}


@media screen and (min-width:769px) {
  .company_page-header{
    border-bottom: 50px solid var(--button-background);
    max-width: 250px;
  }

  .company_pg-pin{
    font-size: 20px;
  }

  .span-company{
    font-size: 14px;
  }
}

.company_page-header{
  /* border-bottom: 50px solid red; */
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  /* margin-bottom: 10px; */
  position: relative;
  width: 100%;
  /* max-width: 250px; */
  display: flex;
  place-content: center;
}



.company_pg-pin{
  /* font-size: 20px; */
  margin-right: 7px;
  margin-bottom: 5px;
}




.span-company{
font-weight: 600;
/* font-size: 15px; */
}

.company_pg-row{
  background: black;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

@media screen and (min-width: 769px) {
  .company_pg-bg{
    max-width: 190px;
  }

  .company_title-about{
    font-size: 14px;
  }

  .company_title-header{
    font-size: 32px;
  }
}

@media screen and (max-width: 768px) {
  .company_pg-bg{
    max-width: 160px;
  }

  .company_title-about{
  font-size: 11px;
  }

.company_title-header{
  font-size: 25px;
}
}



.company_pg-bg{    
  border-bottom: 33px solid var(--button-background);
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  position: relative;
  width: 100%;
  /* max-width: 190px; */
  display: flex;
  place-content: center;
}


.company_title-about{
  /* font-size: 13px;
  justify-content: center;
  position: absolute;
  bottom: -2vh;
  line-height: 0.2; */

  /* font-size: 14px; */
  justify-content: center;
  position: absolute;
  align-items: center;
  /* display: flex; */
  margin-top: 0.6vh;
}



.company_title-header{
  line-height: 2.2;
  /* font-size: 42px; */
}


.company_pg-hr{
  margin-bottom: 15px;
}

.company_pg-desc{
  font-size:16px;
  color: #adb5bd;
  width: 100%;
  padding-right: 22.5%;
  padding-left: 22.5%;
  margin: 0 auto;
  font-weight: 300;
}


@media screen and ( max-width:768px) {
  .company_pg-main {
    grid-template-columns: repeat(1,1fr);
  }
  .company_pg-desc-items{
    font-size: 10px;
  }
  .company_pg-desc{
    font-size: 13px;

  }
}

@media screen and ( min-width:769px) {
  .company_pg-main {
    grid-template-columns: repeat(2,1fr);
  }
  .company_pg-desc-items{
    font-size: 15px;
  }
  .company_pg-desc{
    font-size: 16px;
  }
}


.company_pg-main{
  display: grid;
  width: 100%;
  max-width: 1040px;
  padding: 15px;
  
  
}


.company_pg-about{
  margin-bottom: 40px;
}

.company_pg-left{
margin-bottom: 20px;
align-self: center;
}
/* .company_pg-left{
  flex: 0 0 50%;
  flex-wrap: wrap;
} */

.company_pg-right{
  width: 100%;
  /* flex:0 0 50%;
  max-width: 50%; */
}


.company_pg-list{
  border-bottom: var(--button-background) 1px solid;
  margin-left: 24px;
  margin-bottom: 20px;
}



.company_pg-items{
  display: flex;
  /* justify-content: center; */
}

.company_pg-item {
  margin-right: 10px;
  /* background-color: rgba(173, 181, 189, 0.3); */
  background-color: var(--button-background);
  padding: 12px 15px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

/* .History:active{
  background-color: red;
} */




.company_pg-desc-items{
  margin-left: 20px;
  text-align: start;
  color:#adb5bd;
  width: inherit;
  padding: 0 10px;
}



.company_member{
  width:auto;
}


.copany_member-image{
  /* width: 300px; */
  /* height: 350px; */
  object-fit: contain;
}


@media screen and ( max-width:1024px) {
  .company_member-h2-item{
    font-size: 18px;
  }
  .company_member-desc-item{
    font-size: 15px;
  }
}



@media screen and ( min-width:1025px) {
  .company_member-h2-item{
    font-size: 25px;
  }
  .company_member-desc-item{
    font-size: 20px;
  }
}


.company_member-desc-item{
  font-weight: 200;
}


.company_member-h2-item{
  color: var(--button-background);
  font-weight: 700;
}

.link_team{
  transition: none;
  color: var(--button-background);
  cursor: pointer;
}
.company_member-container ,
.gallery-container
{

  background: #212529;
  padding-top: 100px;
  text-align: -webkit-center;
  padding-bottom:50px ;
}

@media screen and (min-width: 1024px) {
  .company_member-item-container{
    max-width: auto;
  }
}

@media screen and (max-width:1023px)
{.company_member-item-container{
  max-width: 300px;
}
}


@media screen and (min-width: 769px) {
  .company_member-bg,
  .gallery-title-bg
  {
    max-width: 190px;
  }
  
}


@media screen and (max-width: 768px) {
.company_member-bg,
.gallery-title-bg{
  max-width: 140px;
}

}




.company_member-bg ,
.gallery-title-bg{
  border-bottom: 33px solid var(--button-background);
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  position: relative;
  width: 100%;
  /* max-width: 190px; */
  margin-bottom: 15px;
  display: flex;
  place-content: center;
}

@media screen and (max-width: 768px) {
  .gallery-title{
    font-size: 8px!important;
  }
}


.company_member-title ,
.gallery-title{
  /* font-size: 13px;
  justify-content: center;
  position: absolute;
  bottom: -2vh;
  line-height: 0.2; */
  
  font-size: 14px;
  justify-content: center;
  position: absolute;
  align-items: center;
  /* display: flex; */
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  
  .company_member-header{
    
    font-size:28px;
  }
}


@media screen and (min-width: 769px)
{
  .company_member-header{
    
    font-size:30px;
  }
}

.gallery-text{
    
  font-size:28px;
}
.member-hr{
  margin-bottom:10px;
}

.company_member-desc,
.gallery-desc{
  margin: 0 auto;
  padding-bottom: 40px;
}



/* change when adding member */
.company_member-list{
  /* flex: 0 33.33%; */
  width: 100%;
  /* max-width: 1000px; */
  max-width: 50%;
}


@media screen and (max-width: 768px) {
  .company_member-items{
  grid-template-columns: repeat(1, 1fr);
  }
}


@media screen and (min-width: 769px) {
  .company_member-items{
  grid-template-columns: repeat(2, 1fr);
  }
}



.company_member-items{
  justify-content: center;
  place-items: center;
  /* display: grid; */
}

.company_member-item-container{
  border: 1px solid black;
  background: #00000099;
  padding: 30px;
  /* margin: 0 10px; */
  margin-bottom: 40px;
  width: 100%;
}

.company_member-social{
  display: flex;
  justify-content: center;
  margin: 10px;
}

.company_member-social-item{
  margin-right:10px;
  padding: 5px;
  border : 1px solid white;
  background: white;
  color: var(--button-background);
}







/* 


GALLERY


*/


.gallery{
  color: #fff;
}



.bg-black{
  background: #000 !important;;
}




/* 



OUR MAIN SERVICES



*/

.service_plus{
  /* position: absolute; */
  /* right: 3rem; */
  position: absolute;
  right: -2rem;
  color: #fff;
  display: flex;
  justify-content: end;
}

.service-para{
  /* background: none; */
  /* border: none; */
  /* width: 100%; */
  display: none;
  /* max-width: 200px; */
  
  /* display: inline; */
  
  /* display: none; */
  /* padding-left: 70px; */
  
  transition: all 0.5s cubic-bezier(0,1,0,1);
}

.para_show {
  border-top: white 0.5px solid;
  display: flex;
  float: right;
  letter-spacing: 1.2px;
  width: 250px;
  font-weight: 800;
  height: auto;
  max-height: 9999px;
  transition: all 0.5 cubic-bezier(1,0,1,0);
  color: darkgray;
  /* margin-top: 5px; */
  margin-bottom: 20px;
  font-size: 10px;

}

.services_page{
  color: #fff;
  background-color: #000;
}

.p-top10{
  padding-top: 10vh;
}


.s-width{
  width: 100%;
  max-width: 1110px;
}


.service-item_pg {
  background:#191919;

}


.m-50{
  margin: 35px 0px 0 0;
}

/* .services_pg-row{
  background:#151515;
} */





/* 



CONTACT_SEC


*/



.contact_sec{
  width: 100%;
  text-align: -webkit-center;
  padding: 100px 0 ;
  background-color: #191919;
}


.contact_sec-header{
  font-size:42px;
}

.contact_sec-hr{
  margin: 10px 0; 
}


.contact_sec-desc{
  color: white;
  font-size: 16px;
  padding: 50px 0 ;
}

.contact-us {
  background-color: var(--button-background);
  color: #fff;
  padding: 10px;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  cursor: pointer;
  font-size: 15px;
  /* font-weight: 600; */

}


.contact-us:hover{
  border: 1px solid white;
  background-color:transparent;
}





/* 

BENEFIT


*/



.benefit{

  padding-top: 100px;
  padding-right: 0px;
  padding-bottom: 70px;
  padding-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  box-shadow: 0 0 0 0 #ffffff;
}


.benefit-header{
  text-align: start;
  padding: 10px 0;
}

.benefit-container{
  display: flex;
  flex: 1 0 100%;
  width: 100%;
  max-width: 1140px;
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;
}



.benefit-left{
  
  /* flex: 0 0 33.333333%;
  max-width: 33.333333%; */
  background: black;
  padding: 50px 30px;
  
}

.benefit-right{
  display: flex;
  align-self: center;
}


.benefit-items{
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  grid-auto-rows: minmax(108px, auto);
  display: grid;
}


.benefit-item{
  display: flex;
  width: 100%;
  padding-left:15px;
}


.ico{
  width: 200px;
}


.benefit-bg{
  border-bottom: 33px solid var(--button-background);
  border-left: 33px solid transparent;
  border-right: 33px solid transparent;
  position: relative;
  width: 100%;
  max-width: 160px;
  display: flex;
  place-content: center;
}

.benefit-title{

  font-size: 13px;
  justify-content: center;
  position: absolute;
  bottom: -2vh;
  line-height: 0.2;

}


.benefit-col-left{
  align-self: center;
}


.benefit-right-title{
  color:#eeee;
  font-size:22px;
  margin: 0 0 5px;
  text-align: start;
  margin-left: 10px;
}




.benefit-right-desc{
  font-size: 16px;
  color:#adb5bd;
  text-align: start;
  margin-left: 10px;
}






/* 

Project Page


*/

.view_more{
  /* position: absolute;
  bottom: 3rem; */
  align-self: center;
  cursor: pointer;
}

.TbLineDotted{
  font-size: 40px;
}

.projects-pg-main{
  padding-top: 100px;
  padding-bottom: 15px;
  background-color: #000;

  text-align: -webkit-center;

}


.projects-pg-container{
  padding: 0 15px;
  max-width: 1240px;
}



.projects-pg-categories{  
align-content: space-evenly;
}

.project-pg-img{
  width: 100%;
}


.projects-pg-items{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.projects-pg-wrapper{
  padding: 0 15px;
}

.projects-pg-categories{
  margin-bottom: 30px;
}

.projects-pg-categories > a {
  margin-right: 20px;

}

.project-pg-items{
  background:black;
  padding: 0 350px;
}




/* 

MAP

*/

.rcol_title{
  text-align-last:center ;
}

.rcol_container{
  display: inline-block;
}

@media screen and (max-width:768px) {
  .contact-map-main{
    display: inline-block!important;
  }
}


.contact-map-main{
  background:black;
  /* display: flex; */
  place-content:center;
  align-items: center;
  padding-bottom: 50px;
  display: inline-block;
}



.pin_title,
.pin_desc{
  color:black!important;
  margin:5px;
}


.pin_wrapper > a {
  color:blue;
  cursor:pointer;
  text-decoration:underline;
}

.contact-map-row{
  text-align: end;
}
.mapContainerStyle{
  width: 500px;
  height: 500px;
  display: inline-table;
}

.contact_rcol{
justify-self: start;
margin-top: 50px;

}


@media screen and (max-width:1440px) {
  .contact_col{
    font-size: 30px;
  }
}



@media screen and (max-width:2750px) {
  .contact_col{
    font-size: 42px;
  }
}


.contact_col{
  /* font-size: 42px; */
  text-align: start;
  
}

.map_col_hr {
  margin: 0 0 60px;
}

.rcol_items{
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: center;

}

@media screen and (max-width:1240px) {
  
  .rcol_item_r_title{
    font-size: 20px;
  }
}



@media screen and (min-width:1241px) {
  
  .rcol_item_r_title{
    font-size: 24px;
  }
}



/* 
.rcol_item_r{
  margin-left: 20px;
} */


.triangle{ 
  width: auto;
  margin: 0 20px;
  /* height: 0; */
  /* border: 50px solid transparent; */
  /* border-bottom-color: red; */
  position: relative;
  /* top: -50px;
}

  .triangle::after{

    content: '';
    position: absolute;
    left: -50px;
    top: 50px;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    /* border-top-color: red; */
  }


  .rcol_item_l{
    
    /* position: absolute; */
    /* top: 2vh; */
    z-index: 1;
    right: -14px;
    text-align: start;
    font-size: 30px;
    color: var(--button-background);
    margin-left: 50px;

  }




  .loader{
    display: inline-table;
    border: 16px solid #f3f3f3;
    border-top: 16px solid var(--button-background);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    padding: 73px;
    margin-top: 100px;
  }

  .loader-h1 {
    color: #fff;
  }


  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .rcol_item_r_desc{
    width: max-content;
  }

  .rcol_item_r:hover
  .rcol_item_r_desc{
    transition: ease-in-out 0.3s;
    color: #f34a04;
    
  }

@media screen and (max-width: 1050px) {
  .rcol_item_r_desc{
    font-size: 14px;
    margin-top: 5px;
  }
}


  



.contact_form{
  padding-top: 100px;
  padding-right: 0px;
  padding-bottom: 140px;
  padding-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  color: #adb5bd;
  box-shadow: 0 0 0 0 #ffffff;
  background-color: #212529;
}


.contact_form_container{

  max-width: 1140px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.section_title{
  margin-bottom: 40px;
  text-align: -webkit-center;
}

.form_title_heading{

  font-size: 42px;
  line-height: 1.2;
  margin-bottom: 15px;
  color: #FFFFFF;
}


.contact_form-row{
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

@media screen and (max-width: 768px){
  .contact_form-columns{
        /* width: 100%; */
        flex: 0 0 100%;
        /* display: flex; */
        display: grid;
        /* flex-wrap: wrap; */
        grid-template-columns: repeat(1,1fr);
    
  }
}

.contact_form-columns{
  
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
}

.form-group-col{
  margin: 0px 0px 20px 0px;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 20px;
}

.form-control{
  transition: .35s;
  color: #fff;
  border-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: #fff;
  background-color: transparent;
  padding-left: 0;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 2;
  border-radius: 0.125rem;

}


.form-group-col-12{
  width: 100%;
  margin-bottom: 50px;
  border: 1px solid rgba(173, 181, 189, 0.3);
  
}

.form-group-col > select{
  cursor: pointer;
  transition: .35s;
  padding-left: 0;
  width: 100%;
  display: block;
  color: #fff;
  border-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: #fff;
  background-color: transparent!important;
  width: 100%;
  padding: 13px;
  font-size: 1rem;
  line-height: 2; 
  
}

.select-item{
  background-color: black !important;
  
}


.fileUpload{
  display: flex;
  width: 100%;
  justify-content: space-around;
}


.file-list{
  align-self:center;
}

.policy{
  margin:0 5px;
}

.btn-primary{
  cursor:not-allowed;
  background: var(--clr-lg);
  color: white;
  font-size: 17px;
  font-weight: 600;
  padding: 10px;
  border-radius: 2rem;
  margin-top: 20px;
}


.form-check{
  margin-left:10px
}