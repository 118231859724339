
:root{
  --clr-lg:#FF4D00;
  --body: #212531;
  --text-white: #edf0f1;
  --hover-link:#eb6709ac;
  --button-background:#c7501d;
  --body-grey:gainsboro;
  --white:#fff;
}


  /* 
  
  ABOUT
  
  */

  
  .about{
  
    
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  
  }
  
  
  
  
  
  .about-container{
    /* background-color: var(--white); */
    /* width: 100%;
    height: 85vh; */
    height: 500px;
    width:100%;
    /* max-width:1000px; */
    
    /* position: relative; */
    overflow: hidden;
    /* opacity: 0.2; */
  }
  
  .VideoBg {
    width:100%;
    overflow: hidden;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    /* position:fixed; */
    opacity: 0.7;
  }
   @media (min-aspect-ratio: 16/9) {
    .VideoBg {
        width:100%;
        height: auto;
    }
  } 
  @media (max-aspect-ratio: 16/9) {
    .VideoBg { 
        /* width:auto; */
        height: 100%;
    }
  }
  
  .background-video {
    position: absolute;
    /* z-index:0; */
    /* left: 0; */
    width: 100%;
    height: 100%;
    z-index: -1;
    /* background-color:rgba(0,0,0,0.7); */
    top:0;
    overflow: hidden;
  } 
  
   video{
    width: 100%;
    height: 90%;
    object-fit: cover;
  }
  /*  
  /* 
  @media (min-aspect-ratio: 16/9) {
    .background-video {
        width:100%;
        height: auto;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    .background-video { 
        width:auto;
        height: 100%;
    }
  }
  @media (max-width: 767px) {
    .background-video {
        display: none;
    }
    
  } */
  
  
  
  
  .about-header-tx {
    
    /* font-weight: 900; */
    margin: 100px 0 1rem;
    padding: 0;
    line-height: 1;
    /* font-family: Raleway; */
    font-size: 3vw;
    /* font-weight:500; */
    color:white;
    width: 100%;
    /* height: 100%; */
  
  }
    @media screen and (max-width: 435px) {
      .header-tx{
        font-size: 22px;
      }
  
    }
    @media screen and (min-width: 446px) {
      .header-tx{
        font-size: 50px;
      }
    }
  
  .header-tx{
    text-transform: uppercase;
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    height: 50%;
    /* margin-bottom: 50px; */
    /* text-align: center; */
    /* position: relative; */
    /* z-index: 10; */
    /* width: 70%; */
    margin:auto;
    margin-bottom: 30px;
    /* font-size: 60px; */
  }
  

  @media only screen and (min-width: 768px) {
    .header-desc{
    font-size: 18px;
    }
  }

  @media only screen and (max-width: 767px) {
    .header-desc{
        font-size: 12px;
    }
  }

  .header-desc {
    /* font-size: 18px; */
    flex-wrap: wrap;
    width: 600px;
    display: contents;
    color:darkgray;
  }
  
  .btn-parent{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 4%;
    margin-top: 50px;
  
  }
  
  
  .header-btn{
    /* background: linear-gradient(to bottom right, #EF4765, #FF9A5A);
    border: 0;
    border-radius: 12px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 2.5;
    outline: transparent;
    padding: 0 1rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    z-index: 200; */
  
    display: inline-block;
    padding: 0.75rem 1.25rem;
    border-radius: 10rem;
    color: #fff;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 800;
    letter-spacing: 0.15rem;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    z-index: 0;
    cursor: pointer;
    background-color: var(--clr-lg);
  }
  
  /* .header-btn:after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10rem;
    z-index: -1; 
     background-color: var(--clr-lg);
  } */
  
  
  .header-btn:before{
  
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--button-background);
    transition: all 0.3s ease-in-out;
    border-radius: 10rem;
    z-index: -1;
  }
  
  
  /* .header-btn:hover
  {
    color:#fff
  } */
  
  .header-btn:hover:before{
    width: 100%;
    
  }
  
  
  /* .header-btn:hover {
    background-color: var(--clr-lg);
  } */
  
  
  
  /* .header-btn:not([disabled]):focus{
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
  }
  
  .header-btn:not([disabled]):hover{
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
  }
  
  
  
  
  
  
  .header-btn:hover{
    background-color:rgba(184,70,71,1);
    padding:15px 30px;
    border-radius:40px;
  
  } */
  
  
  
  
  