
:root{
  --clr-lg:#FF4D00;
  --body: #212531;
  --text-white: #edf0f1;
  --hover-link:#eb6709ac;
  --button-background:#FF4D00;
  --body-grey:gainsboro;
  --white:#fff;
}


  .header-2#sp-header>.container:before {
    content: "";
    border-bottom: 0 solid transparent;
    border-left: 25px solid transparent;
    border-right: 0 solid transparent;
    position: absolute;
    bottom: 0;
    left: -25px;
  }

  
  @media (min-width: 1200px){
  .header-2#sp-header>.container:after {
      content: "";
      border-bottom: 0 solid transparent;
      border-right: 25px solid transparent;
      border-left: 0 solid transparent;
      position: absolute;
      bottom: 0;
      right: -25px;
  }
  }
  
  
  
  .header-overlap{
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    /* position: fixed; */
  }
  
  
  
  
  #sp-top-bar{
    background: #222222;
    color: #adb5bd;
  }
  
  #sp-top-bar{
    padding: 8px 0;
    font-size: 0.875rem;
  }
  
  
  @media only screen and(min-width: 768px) {
  .d-md-block {
    display: block !important;
  }
  }
  
  
  
  .container {
    padding-left: 15px;
    padding-right: 15px;
  
  }
  
  @media only screen and (min-width: 1200px) {
    .container,
    .cntainer-sm,
    .container-md,
    .conatiner-lg,
    .container-x1 {
      max-width: 1140px;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .container,
    .cntainer-sm,
    .container-md,
    .conatiner-lg {
      max-width: 960px;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .container,
    .cntainer-sm,
    .container-md {
      min-width: 720px;
    }
  }
  
  @media only screen and (min-width: 576px) {
    .container,
    .cntainer-sm {
      min-width: 540px;
    }
  }
  
  .container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    width: 100%;
    /* padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);*/
    margin-right: auto;
    margin-left: auto; 
  }
  
  
  
  
  #sp-top-bar[class*="header-"] .container .container-inner .row {
    align-items: center;
    -webkit-align-items: center;
    justify-content: space-around;
  }
  
  .row{
    --bsgutter-x:1.875rem;
  }
  
  
  .row {
    /* --bs-gutter-x: 1.5rem; */
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
  }
  @media only screen and (min-width:768px) {
    .col-md-8 {
      flex:0 0 auto;
      width: 66.6667%;
    }
  }
  
@media only screen and (max-width: 767px) {

.text-md-start {
  text-align: left;
}
}
  
  .text-center{
    text-align: center !important;
  }
  
  @media only screen and (min-width:768px) {
    .text-md-start{
      text-align:left !important;
    }
  }
  
  .sp-contact-info{
    display: contents;
    list-style: none;
    padding: 0;
    margin:0 -10px;
  }
  

  @media only screen and (max-width: 620px) {
.sp-contact-info {
  font-size: 12px;
}
  }
  
  .sp-contact-info li {
    display: inline-block;
    margin: 0 10px;
    white-space: nowrap;
  }
  

  @media only screen and (max-width: 768) {
    .sp-contact-info li {
      font-size: 12px;
    }
  }
  
  @media only screen and (min-width: 768px) {
    .col-md-4 {
      flex: 0 0 auto;
      width: 33.33%;
    }
  }
  
  .text-center {
    text-align: center !important;
  }
  
  @media only screen and (min-width:768px) {
    .text-md-end {
      text-align: right!important;
    }
  }
  
  
  .header-2#sp-top-bar ul.social-icons {
    margin: -4px;
  }
  
  
  ul.social-icons {
    list-style: none;
    padding: 0;
    margin: -5px;
    display: inline-block;
  }
  
  .header-2#sp-top-bar ul.social-icons>li {
    margin: 4px;
  }
  
  
  ul.social-icons>li {
    display: inline-block;
    margin: 5px 7px;
  }
  
  @media only screen and (min-width: 620px) {
    .header-2#sp-top-bar ul.social-icons>li a {
      border: 1px solid rgba(153, 153, 153, 0.5);
    }
  }
  
  
  .header-2#sp-top-bar ul.social-icons>li a {
    width: 32px;
    height: 32px;
    line-height: 30px;
    text-align: center;
    display: block;
  }
  
  
  
  
  
  
  
  
  
/*


HEADER   2



*/
  
  
@media (min-width: 1200px){
  .header-2#sp-header:before {
      content: "";
      height: 10px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
  }
    }
  
  
  @media only screen and (min-width: 1200px){
    .header-2.header-overlap #sp-header {
      background: transparent;
      box-shadow: none;
  }
  }
  
  
  .header-2#sp-header>.container {
    position: relative;
  }
  
  #sp-header {
    height: 80px;
    left: 0;
    position: relative;
    top: 0;
    width: 100%;
    z-index: 99;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  }
  
  .justify-content-between{
    justify-content: space-between !important
  }
  
  
  
  /* LOGO */
  
  .row .justify-container-between{
    position: relative;
  }
  
  .col-auto {
    flex: 0 0 auto;
    width: auto;
  
  }
  @media (min-width: 768px){
    #sp-logo {
      margin-left: 3rem;
    }
  }

  #sp-logo {
    margin-right: 3rem;
    /* margin-left: 3rem; */
  }
  
  #sp-header #sp-logo .sp-column {
    font-size: 0;
  }
  
  
  #sp-header .logo {
    height: 80px;
    display: inline-flex;
    margin: 0;
    align-items: center;
  }
  
  
  #sp-header .logo a {
    font-size: 24px;
    line-height: 1;
    margin: 0;
    padding: 0;
  }
  

  @media screen and (min-width: 796px) {

    .logo-image{
      width: 150px;
    }
    
  }
  .logo-image{
    width: 150px;
  }
  
  img {
      display: block;
      max-width: 100%;
      height: auto;
  }
  
  img, svg {
    vertical-align: middle;
  } 
  
  
  
  
  
  
  /*   MENU   */
  
  
  @media screen and (max-width: 880px) {
    .d-lg-block {
      display: none!important;
    }  
  }
  
  
  .flex-auto {
    flex: auto;
    position: static;
  }
  
  .align-items-center {
    align-items: center !important;
  }
  
  
  
  .justify-content-end {
    justify-content: flex-end !important;
  }
  
  .d-flex {
    display: flex !important;
  }
  
  .d-grid{
    display: grid !important;
  }
  
  .sp-megamenu-parent {
    list-style: none;
    margin: 0 -15px;
    z-index: 99;
    display: block;
    float: right;
    position: static;
  }
  
  
  .d-lg-block {
    display: block;
  }
  
  
  .sp-megamenu-parent>li {
    display: inline-block;
    position: relative;
    transition: all 0.4s ease-in-out;
  
  }
  
  
  
  .sp-megamenu-parent>li.active:hover>a {
    color: #000;
    
  
  }
  
  
  .sp-megamenu-parent>li>a {
    color: rgba(255, 255, 255, 0.89);
    transition: all 0.4s ease-in-out;
  
  }
  
@media screen and (max-width: 768px) {
  .nav_link_item
{
  font-size: 12px;
}  
}

  .sp-megamenu-parent>li>a, .sp-megamenu-parent>li>span {
    display: inline-block;
    padding: 0 15px;
    line-height: 80px;
    margin: 0;
    font-weight: 500;
  }
  
  
  
  
  /* SIDE NAVBAR  */
  
  
  
  .navBar {
    position: relative;
  }
  
  .navBar button {    
    position: absolute;
    right: 3rem;
    top: 1.5rem;
    cursor: pointer;
  }
  
  
  @media (min-width: 880px) {
    .sidebar-toggle{
    display:none;
    }
  }
  
  
  
  .menuNav {
    /* overflow-y: scroll; */
    list-style: none;
    /* display: none; */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background-color: #000;
    /* z-index: -20; */
    opacity: 0;
    display: none;

    /* width: 100%; */
    overflow: hidden;
    max-width: 100%;
    /* z-index: 9; */
  }
  
  /* .menuNav, */
  .showMenu {
    /* display:contents; */
    opacity: 0.8;
    width: 100%;
    z-index: 1;
    display: inline!important;
  }
  
  
  
  .menuNav li:first-child {
    margin-top: 7rem;
  }
  
  
  .navBar .sidebar-toggle {
    background: transparent;;
    border: none;
    z-index: 5;
  }
  
  .sidebar-parent{
    text-align: center;
    line-height: 3.5;
  }
  
  
  .sidebar-logo{
    position: absolute;
    width: 100px;
    bottom: 70px;
    right: 0;
  }
  
  
  .sidebar-menu-item{
    color: white !important;;
  }
  
  .sidebar-menu-item:focus
  {
    color:var(--);
  }

.banner-container
{
  display: flex;
  margin-bottom: 10px;
}

.Header-banner
  {
    height: 100px;
  }
.espaImg{
  margin-left: 10px;
}


.social-icon-accesibility{
  position: absolute;
}
.social-icon-accesibility-img{
  width: 32px;
}