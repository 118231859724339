.galleryWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
    max-width: 1110px;
    margin: 0 auto;
  }
  
  .galleryWrap .single {
    width: 350px;
    cursor: pointer;
  }
  
  .galleryWrap .single img {
    /* max-width: 100%; */
    width: 250px;
    height: 250px;
  }
  
  .galleryWrap .single img:hover {
    transform: scale(1.02);
  }
  
  .sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.86);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
  }
  
  .btnNext:hover, .btnPrev:hover, .btnClose:hover {
    opacity: 1;
  }
  
  .sliderWrap .btnClose {
    top: 40px;
    right: 40px;
  }
  
  .sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
  }
  
  .sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
  
  .fullScreenImage {    
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: grid;
    align-items: center;
    align-content: center;
    /* justify-content: center; */
    place-content: space-evenly;
    border: 1px solid darkgray;
  }

  @media  screen and  (max-width: 768px) {
    .fullScreenImage{
      grid-template-columns: repeat(1,1fr);
      
    }
    .fullScreenImage > img {
      height: 250px;
    }
  }


  

  @media  screen and  (max-width:1440px) {
    .fullScreenImage{
      max-width: 100%;
      max-height: 80%;

    }
  }  

  @media  screen and  (max-width: 1024px){
    .fullScreen_title{
      font-size: 40px;
    }
    .slideContact {
      font-size:50px;
    }
    .slider_list{
      font-size: 40px;
    }
  
  }

  @media  screen and  (max-width:2570px) {
  /* .fullScreen_title{
      font-size: 50px;
    } */
    .slideContact{
      margin-top: 50px;
      font-size: 25px;
    }

  .slider_list{
    font-size: 20px;
    margin: 0 20px;
    display: flex;
    align-items: center;     
  }
  
  .fullScreenImage > img {
    height: 350px;
  }
}





  
@media screen and  (max-width:425px) {
  .fullScreen_title{
    font-size: 25px;
  }
  .fullScreenImage > img{ 
    height: 200px;
  }

  .slideContact{
    font-size: 15px;
  }
  .slider_list{
    font-size: 10px;
  }

}
  
@media screen and  (max-width:375px) {
  .fullScreen_title{
    font-size: 25px;
  }
  .fullScreenImage > img{ 
    height: 200px;
  }

  .slideContact{
    font-size: 15px;
  }
  .slider_list{
    font-size: 10px;
  }

}

@media screen and  (max-width:320px) {
  .fullScreen_title{
    font-size: 20px;
  }
  .fullScreenImage > img{ 
    height: 150px;
  }

  .slideContact{
    font-size: 15px;
  }
  .slider_list{
    font-size: 10px;
  }

}
  

  
  .fullScreenImage > img {
    object-fit: contain;
    width: 100%;
    align-content: center;
    justify-self: center;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */  
  }

  .fullScreen_title{
    /* font-size: 40px; */
    color: #ff0000c7;
    margin-top: 20px;
  }

  .slideContact:hover{
    text-decoration: underline;
  }


  .slideContact{
    color: darkgray;
    opacity: 0.8;
    /* margin-top: 50px; */
    transition: all 0.20s;
  }

  .slider_contact{    
    margin-top: 20px;
    display: flex;
    place-items: center;
    justify-content: center;
    width: 100%;
    /* place-content: space-around; */
  }
  

  .slider_link{
    margin-left: 10px;
  }